import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import Swiper from 'swiper';
import bodymovin from 'lottie-web';
import ScrollOut from "scroll-out";

// Intiate Vuejs instance
var app = new Vue({
    el: '#app',
    delimiters: ["${","}"],
    components: {
        Breakpoints,
    }
})

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    const lottieHome = document.getElementById('lottie-home');

    const animation = bodymovin.loadAnimation({
        container: lottieHome, // Required
        path: 'img/lottie/WAVE10.json', // Required reference to JSON animation file
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: false, // Optional
    });

    ScrollOut({
        onShown: function(lottieHome) {
            animation.play();

        }
    });

    fadeInPage();

    // Page transition functionality
    function fadeInPage() {
        if (!window.AnimationEvent) { return; }
        var fader = document.getElementById('fader');
        fader.classList.add('fade-out');
    }

    if (!window.AnimationEvent) { return; }

    var anchors = document.querySelectorAll('.fade-btn');

    for (var idx=0; idx<anchors.length; idx+=1) {
        if (anchors[idx].hostname !== window.location.hostname) {
            continue;
        }
        anchors[idx].addEventListener('click', function(event) {
                var fader = document.getElementById('fader'),
                    anchor = event.currentTarget;
                var listener = function() {
                    window.location = anchor.href;
                    fader.removeEventListener('animationend', listener);
                };
                fader.addEventListener('animationend', listener);
                event.preventDefault();
                fader.classList.add('fade-in');
        });
    }

    window.addEventListener('pageshow', function (event) {
        console.log(event);

        if (!event.persisted) {
            return;
        }
        var fader = document.getElementById('fader');
        fader.classList.remove('fade-in');
    });


    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    //Swiper 
    var swiper = new Swiper('.swiper-container', {
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
        speed: 600,
        grabCursor: true,
        loop: true,
        pagination: {
        el: '.swiper-pagination',
      },
    });

    // Mobile menu
    var navTrigger = document.querySelector(".js-open-nav");
    var mobNav = document.querySelector(".mobile");

    if(navTrigger){
        bindEvent(navTrigger, "click", function() {
            navTrigger.classList.toggle("is-active");
            mobNav.classList.toggle("js-is-open");
        }, false);
    }

    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {

        // This has been added because the a element had nested elements
        var accordionItem = e.target;

        if (e.target.tagName !== 'A') {
            accordionItem = e.target.parentElement;
        }

        if(accordionItem.parentElement.classList.contains('accordion-item--active')) {
            accordionItem.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === accordionItem) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
